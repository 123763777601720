// src/Welcome.tsx
import React from 'react';
import SVGComponent from './assets/logo';

const Welcome: React.FC = () => {
  return (
    <div style={styles.container}>
       {/* <img src={logo} alt="Axios Life Logo" style={styles.logo} /> */}
       <SVGComponent />
      {/* <h1 style={styles.title}>Welcome to Axios Life</h1> */}
      <p style={styles.subtitle}>Guiding You to Christ, One Step at a Time.</p> 
      <p style={styles.message}>Stay tuned for more updates!</p>
    </div>
  );
};

const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column' as 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: '#f0f8ff',
      color: '#003366',
      textAlign: 'center' as 'center',
    },
    logo: {
      width: '150px', // Adjust the size as needed
      height: 'auto',
      marginBottom: '20px',
    },
    title: {
      fontSize: '3rem',
      margin: '0',
    },
    subtitle: {
      fontSize: '1.5rem',
      margin: '0.5rem 0',
    },
    message: {
      fontSize: '1rem',
      margin: '1rem 0',
    },
  };
  

export default Welcome;