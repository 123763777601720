import * as React from "react";
const SVGComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={500}
    zoomAndPan="magnify"
    viewBox="0 0 375 374.999991"
    height={500}
    preserveAspectRatio="xMidYMid meet"
    {...props}
  >
    <defs>
      <g />
      <clipPath id="4e2b4df623">
        <path
          d="M 227 167.636719 L 267 167.636719 L 267 202 L 227 202 Z M 227 167.636719 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="d3059ca50c">
        <path
          d="M 265.617188 167.671875 C 268.414062 176.929688 238.039062 189.882812 227.742188 194.574219 L 227.800781 195.707031 C 227.910156 197.671875 228.621094 199.421875 229.792969 201.019531 C 261.003906 187.632812 270.929688 178.746094 265.617188 167.671875 Z M 265.617188 167.671875 "
          clipRule="nonzero"
        />
      </clipPath>
      <linearGradient
        x1={72.139862}
        gradientTransform="matrix(0.0861294, 0, 0, 0.0861294, 221.527559, 167.670776)"
        y1={193.59}
        x2={573.569863}
        gradientUnits="userSpaceOnUse"
        y2={193.59}
        id="fb9e3a2f74"
      >
        <stop
          stopOpacity={1}
          stopColor="rgb(79.998779%, 79.998779%, 79.998779%)"
          offset={0}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(80.084229%, 79.899597%, 79.78363%)"
          offset={0.00390625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(80.171204%, 79.800415%, 79.570007%)"
          offset={0.0078125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(80.256653%, 79.701233%, 79.356384%)"
          offset={0.0117187}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(80.343628%, 79.602051%, 79.142761%)"
          offset={0.015625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(80.429077%, 79.502869%, 78.927612%)"
          offset={0.0195312}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(80.516052%, 79.403687%, 78.713989%)"
          offset={0.0234375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(80.601501%, 79.302979%, 78.49884%)"
          offset={0.0273437}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(80.686951%, 79.203796%, 78.285217%)"
          offset={0.03125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(80.7724%, 79.104614%, 78.071594%)"
          offset={0.0351562}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(80.859375%, 79.005432%, 77.857971%)"
          offset={0.0390625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(80.944824%, 78.90625%, 77.642822%)"
          offset={0.0429687}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(81.031799%, 78.807068%, 77.429199%)"
          offset={0.046875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(81.117249%, 78.707886%, 77.21405%)"
          offset={0.0507812}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(81.202698%, 78.608704%, 77.000427%)"
          offset={0.0546875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(81.288147%, 78.509521%, 76.786804%)"
          offset={0.0585938}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(81.375122%, 78.410339%, 76.573181%)"
          offset={0.0625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(81.460571%, 78.311157%, 76.358032%)"
          offset={0.0664062}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(81.547546%, 78.211975%, 76.144409%)"
          offset={0.0703125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(81.632996%, 78.111267%, 75.92926%)"
          offset={0.0742188}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(81.719971%, 78.012085%, 75.715637%)"
          offset={0.078125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(81.80542%, 77.912903%, 75.502014%)"
          offset={0.0820312}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(81.890869%, 77.813721%, 75.288391%)"
          offset={0.0859375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(81.976318%, 77.714539%, 75.073242%)"
          offset={0.0898437}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(82.063293%, 77.615356%, 74.859619%)"
          offset={0.09375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(82.148743%, 77.516174%, 74.64447%)"
          offset={0.0976562}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(82.235718%, 77.416992%, 74.430847%)"
          offset={0.101562}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(82.321167%, 77.31781%, 74.217224%)"
          offset={0.105469}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(82.406616%, 77.218628%, 74.003601%)"
          offset={0.109375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(82.492065%, 77.11792%, 73.788452%)"
          offset={0.113281}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(82.579041%, 77.018738%, 73.574829%)"
          offset={0.117187}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(82.66449%, 76.919556%, 73.35968%)"
          offset={0.121094}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(82.751465%, 76.820374%, 73.146057%)"
          offset={0.125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(82.836914%, 76.721191%, 72.932434%)"
          offset={0.128906}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(82.923889%, 76.622009%, 72.718811%)"
          offset={0.132812}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(83.009338%, 76.522827%, 72.503662%)"
          offset={0.136719}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(83.094788%, 76.423645%, 72.290039%)"
          offset={0.140625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(83.180237%, 76.324463%, 72.076416%)"
          offset={0.144531}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(83.267212%, 76.225281%, 71.862793%)"
          offset={0.148437}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(83.352661%, 76.124573%, 71.647644%)"
          offset={0.152344}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(83.439636%, 76.025391%, 71.434021%)"
          offset={0.15625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(83.525085%, 75.926208%, 71.218872%)"
          offset={0.160156}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(83.610535%, 75.827026%, 71.005249%)"
          offset={0.164062}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(83.695984%, 75.727844%, 70.791626%)"
          offset={0.167969}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(83.782959%, 75.628662%, 70.578003%)"
          offset={0.171875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(83.868408%, 75.52948%, 70.362854%)"
          offset={0.175781}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(83.955383%, 75.430298%, 70.149231%)"
          offset={0.179687}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(84.040833%, 75.331116%, 69.934082%)"
          offset={0.183594}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(84.127808%, 75.231934%, 69.720459%)"
          offset={0.1875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(84.213257%, 75.132751%, 69.506836%)"
          offset={0.191406}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(84.298706%, 75.033569%, 69.293213%)"
          offset={0.195312}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(84.384155%, 74.932861%, 69.078064%)"
          offset={0.199219}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(84.47113%, 74.833679%, 68.864441%)"
          offset={0.203125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(84.55658%, 74.734497%, 68.649292%)"
          offset={0.207031}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(84.643555%, 74.635315%, 68.435669%)"
          offset={0.210937}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(84.729004%, 74.536133%, 68.222046%)"
          offset={0.214844}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(84.814453%, 74.436951%, 68.008423%)"
          offset={0.21875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(84.899902%, 74.337769%, 67.793274%)"
          offset={0.222656}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(84.986877%, 74.238586%, 67.579651%)"
          offset={0.226562}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(85.072327%, 74.139404%, 67.364502%)"
          offset={0.230469}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(85.159302%, 74.040222%, 67.150879%)"
          offset={0.234375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(85.244751%, 73.939514%, 66.937256%)"
          offset={0.238281}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(85.331726%, 73.840332%, 66.723633%)"
          offset={0.242188}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(85.417175%, 73.74115%, 66.508484%)"
          offset={0.246094}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(85.502625%, 73.641968%, 66.294861%)"
          offset={0.25}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(85.588074%, 73.542786%, 66.079712%)"
          offset={0.253906}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(85.675049%, 73.443604%, 65.866089%)"
          offset={0.257812}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(85.760498%, 73.344421%, 65.652466%)"
          offset={0.261719}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(85.847473%, 73.245239%, 65.438843%)"
          offset={0.265625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(85.932922%, 73.146057%, 65.223694%)"
          offset={0.269531}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(86.018372%, 73.046875%, 65.010071%)"
          offset={0.273438}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(86.103821%, 72.947693%, 64.794922%)"
          offset={0.277344}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(86.190796%, 72.848511%, 64.581299%)"
          offset={0.28125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(86.276245%, 72.747803%, 64.367676%)"
          offset={0.285156}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(86.36322%, 72.648621%, 64.154053%)"
          offset={0.289062}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(86.448669%, 72.549438%, 63.938904%)"
          offset={0.292969}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(86.535645%, 72.450256%, 63.725281%)"
          offset={0.296875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(86.621094%, 72.351074%, 63.510132%)"
          offset={0.300781}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(86.706543%, 72.251892%, 63.296509%)"
          offset={0.304687}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(86.791992%, 72.15271%, 63.082886%)"
          offset={0.308594}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(86.878967%, 72.053528%, 62.869263%)"
          offset={0.3125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(86.964417%, 71.954346%, 62.654114%)"
          offset={0.316406}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(87.051392%, 71.855164%, 62.440491%)"
          offset={0.320312}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(87.136841%, 71.754456%, 62.225342%)"
          offset={0.324219}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(87.22229%, 71.655273%, 62.011719%)"
          offset={0.328125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(87.307739%, 71.556091%, 61.798096%)"
          offset={0.332031}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(87.394714%, 71.456909%, 61.584473%)"
          offset={0.335938}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(87.480164%, 71.357727%, 61.369324%)"
          offset={0.339844}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(87.567139%, 71.258545%, 61.155701%)"
          offset={0.34375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(87.652588%, 71.159363%, 60.940552%)"
          offset={0.347656}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(87.739563%, 71.060181%, 60.726929%)"
          offset={0.351562}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(87.825012%, 70.960999%, 60.513306%)"
          offset={0.355469}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(87.910461%, 70.861816%, 60.299683%)"
          offset={0.359375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(87.995911%, 70.761108%, 60.084534%)"
          offset={0.363281}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(88.082886%, 70.661926%, 59.870911%)"
          offset={0.367187}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(88.168335%, 70.562744%, 59.655762%)"
          offset={0.371094}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(88.25531%, 70.463562%, 59.442139%)"
          offset={0.375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(88.340759%, 70.36438%, 59.228516%)"
          offset={0.378906}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(88.426208%, 70.265198%, 59.014893%)"
          offset={0.382812}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(88.511658%, 70.166016%, 58.799744%)"
          offset={0.386719}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(88.598633%, 70.066833%, 58.586121%)"
          offset={0.390625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(88.684082%, 69.967651%, 58.370972%)"
          offset={0.394531}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(88.771057%, 69.868469%, 58.157349%)"
          offset={0.398438}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(88.856506%, 69.769287%, 57.943726%)"
          offset={0.402344}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(88.943481%, 69.670105%, 57.730103%)"
          offset={0.40625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(89.028931%, 69.569397%, 57.514954%)"
          offset={0.410156}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(89.11438%, 69.470215%, 57.301331%)"
          offset={0.414062}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(89.199829%, 69.371033%, 57.086182%)"
          offset={0.417969}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(89.286804%, 69.271851%, 56.872559%)"
          offset={0.421875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(89.372253%, 69.172668%, 56.658936%)"
          offset={0.425781}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(89.459229%, 69.073486%, 56.445312%)"
          offset={0.429687}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(89.544678%, 68.974304%, 56.230164%)"
          offset={0.433594}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(89.630127%, 68.875122%, 56.016541%)"
          offset={0.4375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(89.715576%, 68.77594%, 55.801392%)"
          offset={0.441406}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(89.802551%, 68.676758%, 55.587769%)"
          offset={0.445312}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(89.888%, 68.57605%, 55.374146%)"
          offset={0.449219}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(89.974976%, 68.476868%, 55.160522%)"
          offset={0.453125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(90.060425%, 68.377686%, 54.945374%)"
          offset={0.457031}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(90.1474%, 68.278503%, 54.73175%)"
          offset={0.460937}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(90.232849%, 68.179321%, 54.518127%)"
          offset={0.464844}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(90.318298%, 68.080139%, 54.304504%)"
          offset={0.46875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(90.403748%, 67.980957%, 54.089355%)"
          offset={0.472656}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(90.490723%, 67.881775%, 53.875732%)"
          offset={0.476562}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(90.576172%, 67.782593%, 53.660583%)"
          offset={0.480469}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(90.663147%, 67.683411%, 53.44696%)"
          offset={0.484375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(90.748596%, 67.584229%, 53.233337%)"
          offset={0.488281}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(90.834045%, 67.485046%, 53.019714%)"
          offset={0.492187}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(90.919495%, 67.384338%, 52.804565%)"
          offset={0.496094}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(91.00647%, 67.285156%, 52.590942%)"
          offset={0.5}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(91.091919%, 67.185974%, 52.375793%)"
          offset={0.503906}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(91.178894%, 67.086792%, 52.16217%)"
          offset={0.507812}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(91.264343%, 66.98761%, 51.948547%)"
          offset={0.511719}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(91.351318%, 66.888428%, 51.734924%)"
          offset={0.515625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(91.436768%, 66.789246%, 51.519775%)"
          offset={0.519531}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(91.522217%, 66.690063%, 51.306152%)"
          offset={0.523437}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(91.607666%, 66.590881%, 51.091003%)"
          offset={0.527344}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(91.694641%, 66.491699%, 50.87738%)"
          offset={0.53125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(91.78009%, 66.390991%, 50.663757%)"
          offset={0.535156}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(91.867065%, 66.291809%, 50.450134%)"
          offset={0.539062}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(91.952515%, 66.192627%, 50.234985%)"
          offset={0.542969}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(92.037964%, 66.093445%, 50.021362%)"
          offset={0.546875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(92.123413%, 65.994263%, 49.806213%)"
          offset={0.550781}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(92.210388%, 65.895081%, 49.59259%)"
          offset={0.554688}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(92.295837%, 65.795898%, 49.378967%)"
          offset={0.558594}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(92.382812%, 65.696716%, 49.165344%)"
          offset={0.5625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(92.468262%, 65.597534%, 48.950195%)"
          offset={0.566406}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(92.555237%, 65.498352%, 48.736572%)"
          offset={0.570312}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(92.640686%, 65.397644%, 48.521423%)"
          offset={0.574219}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(92.726135%, 65.298462%, 48.3078%)"
          offset={0.578125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(92.811584%, 65.19928%, 48.094177%)"
          offset={0.582031}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(92.89856%, 65.100098%, 47.880554%)"
          offset={0.585937}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(92.984009%, 65.000916%, 47.665405%)"
          offset={0.589844}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(93.070984%, 64.901733%, 47.451782%)"
          offset={0.59375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(93.156433%, 64.802551%, 47.236633%)"
          offset={0.597656}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(93.241882%, 64.703369%, 47.02301%)"
          offset={0.601562}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(93.327332%, 64.604187%, 46.809387%)"
          offset={0.605469}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(93.414307%, 64.505005%, 46.595764%)"
          offset={0.609375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(93.499756%, 64.405823%, 46.380615%)"
          offset={0.613281}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(93.586731%, 64.306641%, 46.166992%)"
          offset={0.617187}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(93.67218%, 64.205933%, 45.951843%)"
          offset={0.621094}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(93.759155%, 64.10675%, 45.73822%)"
          offset={0.625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(93.844604%, 64.007568%, 45.524597%)"
          offset={0.628906}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(93.930054%, 63.908386%, 45.310974%)"
          offset={0.632812}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(94.015503%, 63.809204%, 45.095825%)"
          offset={0.636719}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(94.102478%, 63.710022%, 44.882202%)"
          offset={0.640625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(94.187927%, 63.61084%, 44.667053%)"
          offset={0.644531}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(94.274902%, 63.511658%, 44.45343%)"
          offset={0.648437}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(94.360352%, 63.412476%, 44.239807%)"
          offset={0.652344}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(94.445801%, 63.313293%, 44.026184%)"
          offset={0.65625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(94.53125%, 63.212585%, 43.811035%)"
          offset={0.660156}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(94.618225%, 63.113403%, 43.597412%)"
          offset={0.664062}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(94.703674%, 63.014221%, 43.382263%)"
          offset={0.667969}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(94.790649%, 62.915039%, 43.16864%)"
          offset={0.671875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(94.876099%, 62.815857%, 42.955017%)"
          offset={0.675781}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(94.963074%, 62.716675%, 42.741394%)"
          offset={0.679688}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(95.048523%, 62.617493%, 42.526245%)"
          offset={0.683594}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(95.133972%, 62.518311%, 42.312622%)"
          offset={0.6875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(95.219421%, 62.419128%, 42.097473%)"
          offset={0.691406}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(95.306396%, 62.319946%, 41.88385%)"
          offset={0.695312}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(95.391846%, 62.220764%, 41.670227%)"
          offset={0.699219}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(95.478821%, 62.121582%, 41.456604%)"
          offset={0.703125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(95.56427%, 62.020874%, 41.241455%)"
          offset={0.707031}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(95.649719%, 61.921692%, 41.027832%)"
          offset={0.710937}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(95.735168%, 61.82251%, 40.812683%)"
          offset={0.714844}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(95.822144%, 61.723328%, 40.59906%)"
          offset={0.71875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(95.907593%, 61.624146%, 40.385437%)"
          offset={0.722656}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(95.994568%, 61.524963%, 40.171814%)"
          offset={0.726562}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(96.080017%, 61.425781%, 39.956665%)"
          offset={0.730469}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(96.166992%, 61.326599%, 39.743042%)"
          offset={0.734375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(96.252441%, 61.227417%, 39.527893%)"
          offset={0.738281}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(96.337891%, 61.128235%, 39.31427%)"
          offset={0.742187}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(96.42334%, 61.027527%, 39.100647%)"
          offset={0.746094}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(96.510315%, 60.928345%, 38.887024%)"
          offset={0.75}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(96.595764%, 60.829163%, 38.671875%)"
          offset={0.753906}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(96.682739%, 60.72998%, 38.458252%)"
          offset={0.757812}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(96.768188%, 60.630798%, 38.243103%)"
          offset={0.761719}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(96.853638%, 60.531616%, 38.02948%)"
          offset={0.765625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(96.939087%, 60.432434%, 37.815857%)"
          offset={0.769531}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(97.026062%, 60.333252%, 37.602234%)"
          offset={0.773437}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(97.111511%, 60.23407%, 37.387085%)"
          offset={0.777344}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(97.198486%, 60.134888%, 37.173462%)"
          offset={0.78125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(97.283936%, 60.03418%, 36.959839%)"
          offset={0.785156}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(97.370911%, 59.934998%, 36.746216%)"
          offset={0.789062}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(97.45636%, 59.835815%, 36.531067%)"
          offset={0.792969}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(97.541809%, 59.736633%, 36.317444%)"
          offset={0.796875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(97.627258%, 59.637451%, 36.102295%)"
          offset={0.800781}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(97.714233%, 59.538269%, 35.888672%)"
          offset={0.804688}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(97.799683%, 59.439087%, 35.675049%)"
          offset={0.808594}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(97.886658%, 59.339905%, 35.461426%)"
          offset={0.8125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(97.972107%, 59.240723%, 35.246277%)"
          offset={0.816406}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(98.057556%, 59.141541%, 35.032654%)"
          offset={0.820312}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(98.143005%, 59.042358%, 34.817505%)"
          offset={0.824219}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(98.22998%, 58.943176%, 34.603882%)"
          offset={0.828125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(98.31543%, 58.842468%, 34.390259%)"
          offset={0.832031}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(98.402405%, 58.743286%, 34.176636%)"
          offset={0.835937}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(98.487854%, 58.644104%, 33.961487%)"
          offset={0.839844}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(98.574829%, 58.544922%, 33.747864%)"
          offset={0.84375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(98.660278%, 58.44574%, 33.532715%)"
          offset={0.847656}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(98.745728%, 58.346558%, 33.319092%)"
          offset={0.851562}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(98.831177%, 58.247375%, 33.105469%)"
          offset={0.855469}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(98.918152%, 58.148193%, 32.891846%)"
          offset={0.859375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(99.003601%, 58.049011%, 32.676697%)"
          offset={0.863281}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(99.090576%, 57.949829%, 32.463074%)"
          offset={0.867187}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(99.176025%, 57.849121%, 32.247925%)"
          offset={0.871094}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(99.261475%, 57.749939%, 32.034302%)"
          offset={0.875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(99.346924%, 57.650757%, 31.820679%)"
          offset={0.878906}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(99.433899%, 57.551575%, 31.607056%)"
          offset={0.882812}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(99.519348%, 57.452393%, 31.391907%)"
          offset={0.886719}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(99.606323%, 57.35321%, 31.178284%)"
          offset={0.890625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(99.691772%, 57.254028%, 30.963135%)"
          offset={0.894531}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(99.778748%, 57.154846%, 30.749512%)"
          offset={0.898437}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(99.864197%, 57.055664%, 30.535889%)"
          offset={0.902344}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(99.949646%, 56.956482%, 30.322266%)"
          offset={0.90625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(99.97406%, 56.92749%, 30.259705%)"
          offset={0.9375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(100%, 56.898499%, 30.198669%)"
          offset={1}
        />
      </linearGradient>
      <clipPath id="1c9d33c84b">
        <path
          d="M 230 174 L 270 174 L 270 207 L 230 207 Z M 230 174 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="0ada538eb3">
        <path
          d="M 268.195312 175.753906 C 266.136719 186.507812 244.863281 196.488281 230.746094 202.265625 L 230.742188 202.269531 C 232.480469 203.878906 234.703125 205.34375 237.230469 206.742188 C 265.839844 194.167969 273.421875 185.046875 268.429688 174.398438 C 268.375 174.859375 268.300781 175.3125 268.195312 175.753906 Z M 268.195312 175.753906 "
          clipRule="evenodd"
        />
      </clipPath>
      <linearGradient
        x1={106.969895}
        gradientTransform="matrix(0.0861294, 0, 0, 0.0861294, 221.527559, 167.670776)"
        y1={265.883}
        x2={602.519872}
        gradientUnits="userSpaceOnUse"
        y2={265.883}
        id="148746cd6c"
      >
        <stop
          stopOpacity={1}
          stopColor="rgb(79.998779%, 79.998779%, 79.998779%)"
          offset={0}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(80.084229%, 79.901123%, 79.786682%)"
          offset={0.00390625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(80.169678%, 79.803467%, 79.576111%)"
          offset={0.0078125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(80.253601%, 79.704285%, 79.364014%)"
          offset={0.0117187}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(80.33905%, 79.606628%, 79.151917%)"
          offset={0.015625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(80.4245%, 79.507446%, 78.938293%)"
          offset={0.0195312}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(80.509949%, 79.408264%, 78.726196%)"
          offset={0.0234375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(80.595398%, 79.309082%, 78.514099%)"
          offset={0.0273437}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(80.680847%, 79.211426%, 78.302002%)"
          offset={0.03125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(80.766296%, 79.112244%, 78.089905%)"
          offset={0.0351562}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(80.851746%, 79.014587%, 77.877808%)"
          offset={0.0390625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(80.935669%, 78.915405%, 77.66571%)"
          offset={0.0429688}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(81.021118%, 78.817749%, 77.453613%)"
          offset={0.046875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(81.106567%, 78.718567%, 77.23999%)"
          offset={0.0507812}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(81.192017%, 78.620911%, 77.027893%)"
          offset={0.0546875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(81.277466%, 78.521729%, 76.815796%)"
          offset={0.0585938}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(81.362915%, 78.424072%, 76.603699%)"
          offset={0.0625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(81.448364%, 78.32489%, 76.391602%)"
          offset={0.0664062}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(81.533813%, 78.227234%, 76.179504%)"
          offset={0.0703125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(81.617737%, 78.128052%, 75.967407%)"
          offset={0.0742188}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(81.703186%, 78.030396%, 75.75531%)"
          offset={0.078125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(81.788635%, 77.931213%, 75.541687%)"
          offset={0.0820312}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(81.874084%, 77.833557%, 75.32959%)"
          offset={0.0859375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(81.959534%, 77.734375%, 75.117493%)"
          offset={0.0898437}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(82.044983%, 77.636719%, 74.905396%)"
          offset={0.09375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(82.130432%, 77.537537%, 74.693298%)"
          offset={0.0976562}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(82.215881%, 77.43988%, 74.481201%)"
          offset={0.101562}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(82.299805%, 77.340698%, 74.269104%)"
          offset={0.105469}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(82.385254%, 77.243042%, 74.057007%)"
          offset={0.109375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(82.470703%, 77.14386%, 73.84491%)"
          offset={0.113281}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(82.556152%, 77.046204%, 73.632812%)"
          offset={0.117187}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(82.641602%, 76.947021%, 73.419189%)"
          offset={0.121094}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(82.727051%, 76.849365%, 73.207092%)"
          offset={0.125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(82.8125%, 76.750183%, 72.994995%)"
          offset={0.128906}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(82.897949%, 76.652527%, 72.782898%)"
          offset={0.132812}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(82.981873%, 76.553345%, 72.570801%)"
          offset={0.136719}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(83.067322%, 76.455688%, 72.358704%)"
          offset={0.140625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(83.152771%, 76.356506%, 72.146606%)"
          offset={0.144531}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(83.23822%, 76.25885%, 71.934509%)"
          offset={0.148437}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(83.323669%, 76.159668%, 71.720886%)"
          offset={0.152344}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(83.409119%, 76.060486%, 71.508789%)"
          offset={0.15625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(83.494568%, 75.961304%, 71.296692%)"
          offset={0.160156}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(83.580017%, 75.863647%, 71.084595%)"
          offset={0.164062}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(83.66394%, 75.764465%, 70.872498%)"
          offset={0.167969}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(83.74939%, 75.666809%, 70.6604%)"
          offset={0.171875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(83.834839%, 75.567627%, 70.448303%)"
          offset={0.175781}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(83.920288%, 75.469971%, 70.236206%)"
          offset={0.179687}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(84.005737%, 75.370789%, 70.022583%)"
          offset={0.183594}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(84.091187%, 75.273132%, 69.810486%)"
          offset={0.1875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(84.17511%, 75.17395%, 69.598389%)"
          offset={0.191406}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(84.260559%, 75.076294%, 69.386292%)"
          offset={0.195312}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(84.346008%, 74.977112%, 69.174194%)"
          offset={0.199219}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(84.431458%, 74.879456%, 68.962097%)"
          offset={0.203125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(84.516907%, 74.780273%, 68.75%)"
          offset={0.207031}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(84.602356%, 74.682617%, 68.537903%)"
          offset={0.210937}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(84.687805%, 74.583435%, 68.325806%)"
          offset={0.214844}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(84.773254%, 74.485779%, 68.113708%)"
          offset={0.21875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(84.857178%, 74.386597%, 67.900085%)"
          offset={0.222656}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(84.942627%, 74.28894%, 67.687988%)"
          offset={0.226562}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(85.028076%, 74.189758%, 67.475891%)"
          offset={0.230469}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(85.113525%, 74.092102%, 67.263794%)"
          offset={0.234375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(85.198975%, 73.99292%, 67.051697%)"
          offset={0.238281}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(85.284424%, 73.895264%, 66.8396%)"
          offset={0.242187}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(85.369873%, 73.796082%, 66.627502%)"
          offset={0.246094}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(85.455322%, 73.698425%, 66.415405%)"
          offset={0.25}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(85.539246%, 73.599243%, 66.201782%)"
          offset={0.253906}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(85.624695%, 73.501587%, 65.989685%)"
          offset={0.257812}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(85.710144%, 73.402405%, 65.777588%)"
          offset={0.261719}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(85.795593%, 73.304749%, 65.565491%)"
          offset={0.265625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(85.881042%, 73.205566%, 65.353394%)"
          offset={0.269531}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(85.966492%, 73.10791%, 65.141296%)"
          offset={0.273437}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(86.051941%, 73.008728%, 64.929199%)"
          offset={0.277344}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(86.13739%, 72.911072%, 64.717102%)"
          offset={0.28125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(86.221313%, 72.81189%, 64.503479%)"
          offset={0.285156}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(86.306763%, 72.712708%, 64.291382%)"
          offset={0.289062}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(86.392212%, 72.613525%, 64.079285%)"
          offset={0.292969}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(86.477661%, 72.515869%, 63.867188%)"
          offset={0.296875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(86.56311%, 72.416687%, 63.65509%)"
          offset={0.300781}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(86.64856%, 72.319031%, 63.442993%)"
          offset={0.304688}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(86.734009%, 72.219849%, 63.230896%)"
          offset={0.308594}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(86.819458%, 72.122192%, 63.018799%)"
          offset={0.3125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(86.903381%, 72.02301%, 62.806702%)"
          offset={0.316406}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(86.988831%, 71.925354%, 62.594604%)"
          offset={0.320312}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(87.07428%, 71.826172%, 62.380981%)"
          offset={0.324219}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(87.159729%, 71.728516%, 62.168884%)"
          offset={0.328125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(87.245178%, 71.629333%, 61.956787%)"
          offset={0.332031}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(87.330627%, 71.531677%, 61.74469%)"
          offset={0.335937}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(87.416077%, 71.432495%, 61.532593%)"
          offset={0.339844}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(87.501526%, 71.334839%, 61.320496%)"
          offset={0.34375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(87.585449%, 71.235657%, 61.108398%)"
          offset={0.347656}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(87.670898%, 71.138%, 60.896301%)"
          offset={0.351562}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(87.756348%, 71.038818%, 60.682678%)"
          offset={0.355469}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(87.841797%, 70.941162%, 60.470581%)"
          offset={0.359375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(87.927246%, 70.84198%, 60.258484%)"
          offset={0.363281}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(88.012695%, 70.744324%, 60.046387%)"
          offset={0.367187}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(88.096619%, 70.645142%, 59.83429%)"
          offset={0.371094}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(88.182068%, 70.547485%, 59.622192%)"
          offset={0.375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(88.267517%, 70.448303%, 59.410095%)"
          offset={0.378906}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(88.352966%, 70.350647%, 59.197998%)"
          offset={0.382812}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(88.438416%, 70.251465%, 58.985901%)"
          offset={0.386719}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(88.523865%, 70.153809%, 58.773804%)"
          offset={0.390625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(88.609314%, 70.054626%, 58.560181%)"
          offset={0.394531}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(88.694763%, 69.95697%, 58.348083%)"
          offset={0.398437}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(88.778687%, 69.857788%, 58.135986%)"
          offset={0.402344}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(88.864136%, 69.760132%, 57.923889%)"
          offset={0.40625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(88.949585%, 69.66095%, 57.711792%)"
          offset={0.410156}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(89.035034%, 69.563293%, 57.499695%)"
          offset={0.414062}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(89.120483%, 69.464111%, 57.287598%)"
          offset={0.417969}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(89.205933%, 69.364929%, 57.0755%)"
          offset={0.421875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(89.291382%, 69.265747%, 56.861877%)"
          offset={0.425781}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(89.376831%, 69.168091%, 56.64978%)"
          offset={0.429687}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(89.460754%, 69.068909%, 56.437683%)"
          offset={0.433594}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(89.546204%, 68.971252%, 56.225586%)"
          offset={0.4375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(89.631653%, 68.87207%, 56.013489%)"
          offset={0.441406}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(89.717102%, 68.774414%, 55.801392%)"
          offset={0.445312}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(89.802551%, 68.675232%, 55.589294%)"
          offset={0.449219}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(89.888%, 68.577576%, 55.377197%)"
          offset={0.453125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(89.97345%, 68.478394%, 55.163574%)"
          offset={0.457031}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(90.058899%, 68.380737%, 54.951477%)"
          offset={0.460937}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(90.142822%, 68.281555%, 54.73938%)"
          offset={0.464844}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(90.228271%, 68.183899%, 54.527283%)"
          offset={0.46875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(90.313721%, 68.084717%, 54.315186%)"
          offset={0.472656}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(90.39917%, 67.987061%, 54.103088%)"
          offset={0.476562}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(90.484619%, 67.887878%, 53.890991%)"
          offset={0.480469}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(90.570068%, 67.790222%, 53.678894%)"
          offset={0.484375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(90.655518%, 67.69104%, 53.466797%)"
          offset={0.488281}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(90.740967%, 67.593384%, 53.2547%)"
          offset={0.492187}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(90.82489%, 67.494202%, 53.041077%)"
          offset={0.496094}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(90.910339%, 67.396545%, 52.828979%)"
          offset={0.5}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(90.995789%, 67.297363%, 52.616882%)"
          offset={0.503906}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(91.081238%, 67.199707%, 52.404785%)"
          offset={0.507812}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(91.166687%, 67.100525%, 52.192688%)"
          offset={0.511719}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(91.252136%, 67.002869%, 51.980591%)"
          offset={0.515625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(91.337585%, 66.903687%, 51.768494%)"
          offset={0.519531}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(91.423035%, 66.80603%, 51.556396%)"
          offset={0.523437}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(91.506958%, 66.706848%, 51.342773%)"
          offset={0.527344}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(91.592407%, 66.609192%, 51.130676%)"
          offset={0.53125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(91.677856%, 66.51001%, 50.918579%)"
          offset={0.535156}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(91.763306%, 66.412354%, 50.706482%)"
          offset={0.539062}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(91.848755%, 66.313171%, 50.494385%)"
          offset={0.542969}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(91.934204%, 66.215515%, 50.282288%)"
          offset={0.546875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(92.018127%, 66.116333%, 50.07019%)"
          offset={0.550781}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(92.103577%, 66.017151%, 49.858093%)"
          offset={0.554687}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(92.189026%, 65.917969%, 49.64447%)"
          offset={0.558594}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(92.274475%, 65.820312%, 49.432373%)"
          offset={0.5625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(92.359924%, 65.72113%, 49.220276%)"
          offset={0.566406}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(92.445374%, 65.623474%, 49.008179%)"
          offset={0.570312}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(92.530823%, 65.524292%, 48.796082%)"
          offset={0.574219}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(92.616272%, 65.426636%, 48.583984%)"
          offset={0.578125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(92.700195%, 65.327454%, 48.371887%)"
          offset={0.582031}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(92.785645%, 65.229797%, 48.15979%)"
          offset={0.585937}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(92.871094%, 65.130615%, 47.947693%)"
          offset={0.589844}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(92.956543%, 65.032959%, 47.735596%)"
          offset={0.59375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(93.041992%, 64.933777%, 47.521973%)"
          offset={0.597656}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(93.127441%, 64.836121%, 47.309875%)"
          offset={0.601562}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(93.212891%, 64.736938%, 47.097778%)"
          offset={0.605469}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(93.29834%, 64.639282%, 46.885681%)"
          offset={0.609375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(93.382263%, 64.5401%, 46.673584%)"
          offset={0.613281}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(93.467712%, 64.442444%, 46.461487%)"
          offset={0.617187}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(93.553162%, 64.343262%, 46.24939%)"
          offset={0.621094}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(93.638611%, 64.245605%, 46.037292%)"
          offset={0.625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(93.72406%, 64.146423%, 45.823669%)"
          offset={0.628906}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(93.809509%, 64.048767%, 45.611572%)"
          offset={0.632812}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(93.894958%, 63.949585%, 45.399475%)"
          offset={0.636719}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(93.980408%, 63.851929%, 45.187378%)"
          offset={0.640625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(94.064331%, 63.752747%, 44.975281%)"
          offset={0.644531}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(94.14978%, 63.65509%, 44.763184%)"
          offset={0.648437}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(94.235229%, 63.555908%, 44.551086%)"
          offset={0.652344}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(94.320679%, 63.458252%, 44.338989%)"
          offset={0.65625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(94.406128%, 63.35907%, 44.125366%)"
          offset={0.660156}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(94.491577%, 63.261414%, 43.913269%)"
          offset={0.664062}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(94.577026%, 63.162231%, 43.701172%)"
          offset={0.667969}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(94.662476%, 63.064575%, 43.489075%)"
          offset={0.671875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(94.746399%, 62.965393%, 43.276978%)"
          offset={0.675781}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(94.831848%, 62.867737%, 43.06488%)"
          offset={0.679687}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(94.917297%, 62.768555%, 42.852783%)"
          offset={0.683594}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(95.002747%, 62.669373%, 42.640686%)"
          offset={0.6875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(95.088196%, 62.57019%, 42.428589%)"
          offset={0.691406}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(95.173645%, 62.472534%, 42.216492%)"
          offset={0.695312}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(95.259094%, 62.373352%, 42.002869%)"
          offset={0.699219}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(95.344543%, 62.275696%, 41.790771%)"
          offset={0.703125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(95.428467%, 62.176514%, 41.578674%)"
          offset={0.707031}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(95.513916%, 62.078857%, 41.366577%)"
          offset={0.710938}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(95.599365%, 61.979675%, 41.15448%)"
          offset={0.714844}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(95.684814%, 61.882019%, 40.942383%)"
          offset={0.71875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(95.770264%, 61.782837%, 40.730286%)"
          offset={0.722656}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(95.855713%, 61.685181%, 40.518188%)"
          offset={0.726562}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(95.939636%, 61.585999%, 40.304565%)"
          offset={0.730469}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(96.025085%, 61.488342%, 40.092468%)"
          offset={0.734375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(96.110535%, 61.38916%, 39.880371%)"
          offset={0.738281}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(96.195984%, 61.291504%, 39.668274%)"
          offset={0.742187}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(96.281433%, 61.192322%, 39.456177%)"
          offset={0.746094}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(96.366882%, 61.094666%, 39.24408%)"
          offset={0.75}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(96.452332%, 60.995483%, 39.031982%)"
          offset={0.753906}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(96.537781%, 60.897827%, 38.819885%)"
          offset={0.757812}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(96.621704%, 60.798645%, 38.606262%)"
          offset={0.761719}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(96.707153%, 60.700989%, 38.394165%)"
          offset={0.765625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(96.792603%, 60.601807%, 38.182068%)"
          offset={0.769531}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(96.878052%, 60.50415%, 37.969971%)"
          offset={0.773437}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(96.963501%, 60.404968%, 37.757874%)"
          offset={0.777344}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(97.04895%, 60.307312%, 37.545776%)"
          offset={0.78125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(97.134399%, 60.20813%, 37.333679%)"
          offset={0.785156}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(97.219849%, 60.110474%, 37.121582%)"
          offset={0.789062}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(97.303772%, 60.011292%, 36.909485%)"
          offset={0.792969}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(97.389221%, 59.913635%, 36.697388%)"
          offset={0.796875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(97.47467%, 59.814453%, 36.483765%)"
          offset={0.800781}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(97.56012%, 59.716797%, 36.271667%)"
          offset={0.804687}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(97.645569%, 59.617615%, 36.05957%)"
          offset={0.808594}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(97.731018%, 59.519958%, 35.847473%)"
          offset={0.8125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(97.816467%, 59.420776%, 35.635376%)"
          offset={0.816406}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(97.901917%, 59.321594%, 35.423279%)"
          offset={0.820312}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(97.98584%, 59.222412%, 35.211182%)"
          offset={0.824219}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(98.071289%, 59.124756%, 34.999084%)"
          offset={0.828125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(98.156738%, 59.025574%, 34.785461%)"
          offset={0.832031}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(98.242188%, 58.927917%, 34.573364%)"
          offset={0.835937}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(98.327637%, 58.828735%, 34.361267%)"
          offset={0.839844}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(98.413086%, 58.731079%, 34.14917%)"
          offset={0.84375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(98.498535%, 58.631897%, 33.937073%)"
          offset={0.847656}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(98.583984%, 58.534241%, 33.724976%)"
          offset={0.851562}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(98.667908%, 58.435059%, 33.512878%)"
          offset={0.855469}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(98.753357%, 58.337402%, 33.300781%)"
          offset={0.859375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(98.838806%, 58.23822%, 33.087158%)"
          offset={0.863281}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(98.924255%, 58.140564%, 32.875061%)"
          offset={0.867187}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(99.009705%, 58.041382%, 32.662964%)"
          offset={0.871094}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(99.095154%, 57.943726%, 32.450867%)"
          offset={0.875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(99.180603%, 57.844543%, 32.23877%)"
          offset={0.878906}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(99.266052%, 57.746887%, 32.026672%)"
          offset={0.882812}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(99.349976%, 57.647705%, 31.814575%)"
          offset={0.886719}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(99.435425%, 57.550049%, 31.602478%)"
          offset={0.890625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(99.520874%, 57.450867%, 31.390381%)"
          offset={0.894531}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(99.606323%, 57.35321%, 31.178284%)"
          offset={0.898437}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(99.691772%, 57.254028%, 30.964661%)"
          offset={0.902344}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(99.777222%, 57.156372%, 30.752563%)"
          offset={0.90625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(99.861145%, 57.05719%, 30.540466%)"
          offset={0.910156}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(99.946594%, 56.959534%, 30.328369%)"
          offset={0.914062}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(99.972534%, 56.929016%, 30.262756%)"
          offset={0.921875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(100%, 56.898499%, 30.198669%)"
          offset={0.9375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(100%, 56.898499%, 30.198669%)"
          offset={1}
        />
      </linearGradient>
      <clipPath id="e57e83149e">
        <path
          d="M 224 195 L 277 195 L 277 252 L 224 252 Z M 224 195 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="e9bb31364a">
        <path
          d="M 248.722656 222.820312 C 230.570312 215.207031 220.621094 206.519531 226.28125 195.789062 C 227.421875 216.894531 286.566406 214.222656 275.296875 251.269531 C 273.667969 234.824219 260.632812 228.984375 248.722656 222.820312 Z M 248.722656 222.820312 "
          clipRule="evenodd"
        />
      </clipPath>
      <linearGradient
        x1={-10.520135}
        gradientTransform="matrix(0.0861294, 0, 0, 0.0861294, 221.527559, 167.670776)"
        y1={648.547}
        x2={751.290265}
        gradientUnits="userSpaceOnUse"
        y2={648.547}
        id="3d69a65185"
      >
        <stop
          stopOpacity={1}
          stopColor="rgb(3.898621%, 45.098877%, 68.998718%)"
          offset={0}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(3.903198%, 45.109558%, 69.006348%)"
          offset={0.0625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(3.993225%, 45.3125%, 69.168091%)"
          offset={0.0703125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(4.164124%, 45.698547%, 69.474792%)"
          offset={0.078125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(4.335022%, 46.084595%, 69.779968%)"
          offset={0.0859375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(4.504395%, 46.472168%, 70.085144%)"
          offset={0.09375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(4.675293%, 46.858215%, 70.39032%)"
          offset={0.101562}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(4.846191%, 47.244263%, 70.695496%)"
          offset={0.109375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(5.015564%, 47.63031%, 71.000671%)"
          offset={0.117187}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(5.186462%, 48.016357%, 71.305847%)"
          offset={0.125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(5.357361%, 48.402405%, 71.612549%)"
          offset={0.132812}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(5.528259%, 48.788452%, 71.917725%)"
          offset={0.140625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(5.697632%, 49.1745%, 72.2229%)"
          offset={0.148437}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(5.86853%, 49.560547%, 72.528076%)"
          offset={0.15625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(6.039429%, 49.94812%, 72.833252%)"
          offset={0.164062}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(6.208801%, 50.334167%, 73.138428%)"
          offset={0.171875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(6.3797%, 50.720215%, 73.445129%)"
          offset={0.179687}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(6.550598%, 51.106262%, 73.750305%)"
          offset={0.1875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(6.721497%, 51.49231%, 74.055481%)"
          offset={0.195312}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(6.890869%, 51.878357%, 74.360657%)"
          offset={0.203125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(7.061768%, 52.264404%, 74.665833%)"
          offset={0.210937}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(7.232666%, 52.650452%, 74.971008%)"
          offset={0.21875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(7.402039%, 53.036499%, 75.27771%)"
          offset={0.226562}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(7.572937%, 53.424072%, 75.582886%)"
          offset={0.234375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(7.743835%, 53.81012%, 75.888062%)"
          offset={0.242187}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(7.913208%, 54.196167%, 76.193237%)"
          offset={0.25}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(8.084106%, 54.582214%, 76.498413%)"
          offset={0.257812}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(8.255005%, 54.968262%, 76.803589%)"
          offset={0.265625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(8.425903%, 55.354309%, 77.110291%)"
          offset={0.273437}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(8.595276%, 55.740356%, 77.415466%)"
          offset={0.28125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(8.766174%, 56.126404%, 77.720642%)"
          offset={0.289062}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(8.937073%, 56.512451%, 78.025818%)"
          offset={0.296875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(9.106445%, 56.900024%, 78.330994%)"
          offset={0.304687}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(9.277344%, 57.286072%, 78.636169%)"
          offset={0.3125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(9.448242%, 57.672119%, 78.942871%)"
          offset={0.320312}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(9.619141%, 58.058167%, 79.248047%)"
          offset={0.328125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(9.788513%, 58.444214%, 79.553223%)"
          offset={0.335937}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(9.959412%, 58.830261%, 79.858398%)"
          offset={0.34375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(10.13031%, 59.216309%, 80.163574%)"
          offset={0.351562}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(10.299683%, 59.602356%, 80.46875%)"
          offset={0.359375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(10.470581%, 59.988403%, 80.775452%)"
          offset={0.367187}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(10.641479%, 60.375977%, 81.080627%)"
          offset={0.375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(10.810852%, 60.762024%, 81.385803%)"
          offset={0.382812}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(10.98175%, 61.148071%, 81.690979%)"
          offset={0.390625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(11.152649%, 61.534119%, 81.996155%)"
          offset={0.398437}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(11.323547%, 61.920166%, 82.301331%)"
          offset={0.40625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(11.49292%, 62.306213%, 82.608032%)"
          offset={0.414062}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(11.663818%, 62.692261%, 82.913208%)"
          offset={0.421875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(11.834717%, 63.078308%, 83.218384%)"
          offset={0.429687}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(12.004089%, 63.464355%, 83.52356%)"
          offset={0.4375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(12.174988%, 63.851929%, 83.828735%)"
          offset={0.445312}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(12.345886%, 64.237976%, 84.133911%)"
          offset={0.453125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(12.515259%, 64.624023%, 84.440613%)"
          offset={0.460937}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(12.686157%, 65.010071%, 84.745789%)"
          offset={0.46875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(12.857056%, 65.396118%, 85.050964%)"
          offset={0.476562}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(13.027954%, 65.782166%, 85.35614%)"
          offset={0.484375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(13.197327%, 66.168213%, 85.661316%)"
          offset={0.492187}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(13.368225%, 66.55426%, 85.966492%)"
          offset={0.5}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(13.539124%, 66.940308%, 86.273193%)"
          offset={0.507812}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(13.708496%, 67.327881%, 86.578369%)"
          offset={0.515625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(13.879395%, 67.713928%, 86.883545%)"
          offset={0.523438}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(14.050293%, 68.099976%, 87.188721%)"
          offset={0.53125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(14.221191%, 68.486023%, 87.493896%)"
          offset={0.539062}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(14.390564%, 68.87207%, 87.799072%)"
          offset={0.546875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(14.561462%, 69.258118%, 88.105774%)"
          offset={0.554688}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(14.732361%, 69.644165%, 88.41095%)"
          offset={0.5625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(14.901733%, 70.030212%, 88.716125%)"
          offset={0.570312}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(15.072632%, 70.41626%, 89.021301%)"
          offset={0.578125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(15.24353%, 70.803833%, 89.326477%)"
          offset={0.585938}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(15.412903%, 71.18988%, 89.631653%)"
          offset={0.59375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(15.583801%, 71.575928%, 89.938354%)"
          offset={0.601562}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(15.7547%, 71.961975%, 90.24353%)"
          offset={0.609375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(15.925598%, 72.348022%, 90.548706%)"
          offset={0.617188}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(16.094971%, 72.73407%, 90.853882%)"
          offset={0.625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(16.265869%, 73.120117%, 91.159058%)"
          offset={0.632812}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(16.436768%, 73.506165%, 91.464233%)"
          offset={0.640625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(16.60614%, 73.892212%, 91.770935%)"
          offset={0.648438}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(16.777039%, 74.279785%, 92.076111%)"
          offset={0.65625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(16.947937%, 74.665833%, 92.381287%)"
          offset={0.664062}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(17.118835%, 75.05188%, 92.686462%)"
          offset={0.671875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(17.288208%, 75.437927%, 92.991638%)"
          offset={0.679688}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(17.459106%, 75.823975%, 93.296814%)"
          offset={0.6875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(17.630005%, 76.210022%, 93.60199%)"
          offset={0.695312}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(17.799377%, 76.596069%, 93.908691%)"
          offset={0.703125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(17.970276%, 76.982117%, 94.213867%)"
          offset={0.710938}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(18.141174%, 77.368164%, 94.519043%)"
          offset={0.71875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(18.310547%, 77.755737%, 94.824219%)"
          offset={0.726562}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(18.481445%, 78.141785%, 95.129395%)"
          offset={0.734375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(18.652344%, 78.527832%, 95.43457%)"
          offset={0.742188}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(18.823242%, 78.913879%, 95.741272%)"
          offset={0.75}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(18.992615%, 79.299927%, 96.046448%)"
          offset={0.757812}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(19.163513%, 79.685974%, 96.351624%)"
          offset={0.765625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(19.334412%, 80.072021%, 96.656799%)"
          offset={0.773438}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(19.503784%, 80.458069%, 96.961975%)"
          offset={0.78125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(19.674683%, 80.844116%, 97.267151%)"
          offset={0.789062}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(19.845581%, 81.231689%, 97.573853%)"
          offset={0.796875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(20.014954%, 81.617737%, 97.879028%)"
          offset={0.804688}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(20.185852%, 82.003784%, 98.184204%)"
          offset={0.8125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(20.35675%, 82.389832%, 98.48938%)"
          offset={0.820312}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(20.527649%, 82.775879%, 98.794556%)"
          offset={0.828125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(20.697021%, 83.161926%, 99.099731%)"
          offset={0.835938}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(20.86792%, 83.547974%, 99.406433%)"
          offset={0.84375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(21.038818%, 83.934021%, 99.711609%)"
          offset={0.851562}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(21.160889%, 84.213257%, 99.931335%)"
          offset={0.859375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(21.199036%, 84.298706%, 100%)"
          offset={0.875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(21.199036%, 84.298706%, 100%)"
          offset={1}
        />
      </linearGradient>
      <clipPath id="67fe13225f">
        <path
          d="M 221 203 L 274 203 L 274 260.636719 L 221 260.636719 Z M 221 203 "
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="0d16d4f87b">
        <path
          d="M 223.414062 203.082031 C 226.035156 226.339844 284.539062 224.582031 271.320312 260.488281 C 268.128906 228.242188 210.488281 227.601562 223.414062 203.082031 Z M 223.414062 203.082031 "
          clipRule="evenodd"
        />
      </clipPath>
      <linearGradient
        x1={-128.190134}
        gradientTransform="matrix(0.0861294, 0, 0, 0.0861294, 221.527559, 167.670776)"
        y1={744.40198}
        x2={731.59989}
        gradientUnits="userSpaceOnUse"
        y2={744.40198}
        id="34f14438b6"
      >
        <stop
          stopOpacity={1}
          stopColor="rgb(3.898621%, 45.098877%, 68.998718%)"
          offset={0}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(3.898621%, 45.098877%, 68.998718%)"
          offset={0.125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(3.898621%, 45.098877%, 68.998718%)"
          offset={0.140625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(3.898621%, 45.098877%, 68.998718%)"
          offset={0.148438}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(3.987122%, 45.298767%, 69.15741%)"
          offset={0.152344}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(4.077148%, 45.500183%, 69.316101%)"
          offset={0.15625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(4.173279%, 45.71991%, 69.490051%)"
          offset={0.160156}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(4.269409%, 45.939636%, 69.664001%)"
          offset={0.164062}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(4.36554%, 46.157837%, 69.836426%)"
          offset={0.167969}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(4.463196%, 46.377563%, 70.010376%)"
          offset={0.171875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(4.559326%, 46.595764%, 70.1828%)"
          offset={0.175781}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(4.656982%, 46.815491%, 70.35675%)"
          offset={0.179688}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(4.753113%, 47.035217%, 70.529175%)"
          offset={0.183594}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(4.850769%, 47.254944%, 70.703125%)"
          offset={0.1875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(4.946899%, 47.473145%, 70.875549%)"
          offset={0.191406}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(5.04303%, 47.692871%, 71.0495%)"
          offset={0.195313}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(5.13916%, 47.911072%, 71.22345%)"
          offset={0.199219}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(5.236816%, 48.130798%, 71.3974%)"
          offset={0.203125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(5.332947%, 48.348999%, 71.569824%)"
          offset={0.207031}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(5.430603%, 48.568726%, 71.743774%)"
          offset={0.210938}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(5.526733%, 48.788452%, 71.916199%)"
          offset={0.214844}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(5.62439%, 49.008179%, 72.090149%)"
          offset={0.21875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(5.72052%, 49.226379%, 72.262573%)"
          offset={0.222656}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(5.818176%, 49.446106%, 72.436523%)"
          offset={0.226563}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(5.914307%, 49.664307%, 72.608948%)"
          offset={0.230469}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(6.010437%, 49.884033%, 72.782898%)"
          offset={0.234375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(6.106567%, 50.10376%, 72.956848%)"
          offset={0.238281}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(6.204224%, 50.323486%, 73.130798%)"
          offset={0.242188}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(6.300354%, 50.541687%, 73.303223%)"
          offset={0.246094}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(6.39801%, 50.761414%, 73.477173%)"
          offset={0.25}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(6.494141%, 50.979614%, 73.649597%)"
          offset={0.253906}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(6.591797%, 51.199341%, 73.823547%)"
          offset={0.257812}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(6.687927%, 51.417542%, 73.995972%)"
          offset={0.261719}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(6.785583%, 51.637268%, 74.169922%)"
          offset={0.265625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(6.881714%, 51.856995%, 74.342346%)"
          offset={0.269531}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(6.977844%, 52.076721%, 74.516296%)"
          offset={0.273438}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(7.073975%, 52.294922%, 74.688721%)"
          offset={0.277344}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(7.171631%, 52.514648%, 74.862671%)"
          offset={0.28125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(7.267761%, 52.732849%, 75.036621%)"
          offset={0.285156}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(7.365417%, 52.952576%, 75.210571%)"
          offset={0.289063}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(7.461548%, 53.172302%, 75.382996%)"
          offset={0.292969}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(7.559204%, 53.392029%, 75.556946%)"
          offset={0.296875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(7.655334%, 53.610229%, 75.72937%)"
          offset={0.300781}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(7.751465%, 53.829956%, 75.90332%)"
          offset={0.304688}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(7.847595%, 54.048157%, 76.075745%)"
          offset={0.308594}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(7.945251%, 54.267883%, 76.249695%)"
          offset={0.3125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(8.041382%, 54.486084%, 76.422119%)"
          offset={0.316406}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(8.139038%, 54.705811%, 76.596069%)"
          offset={0.320313}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(8.235168%, 54.925537%, 76.77002%)"
          offset={0.324219}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(8.332825%, 55.145264%, 76.94397%)"
          offset={0.328125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(8.428955%, 55.363464%, 77.116394%)"
          offset={0.332031}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(8.526611%, 55.583191%, 77.290344%)"
          offset={0.335938}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(8.622742%, 55.801392%, 77.462769%)"
          offset={0.339844}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(8.718872%, 56.021118%, 77.636719%)"
          offset={0.34375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(8.815002%, 56.240845%, 77.809143%)"
          offset={0.347656}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(8.912659%, 56.460571%, 77.983093%)"
          offset={0.351563}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(9.008789%, 56.678772%, 78.155518%)"
          offset={0.355469}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(9.106445%, 56.898499%, 78.329468%)"
          offset={0.359375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(9.202576%, 57.116699%, 78.503418%)"
          offset={0.363281}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(9.300232%, 57.336426%, 78.677368%)"
          offset={0.367188}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(9.396362%, 57.554626%, 78.849792%)"
          offset={0.371094}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(9.494019%, 57.774353%, 79.023743%)"
          offset={0.375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(9.590149%, 57.99408%, 79.196167%)"
          offset={0.378906}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(9.686279%, 58.213806%, 79.370117%)"
          offset={0.382812}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(9.78241%, 58.432007%, 79.542542%)"
          offset={0.386719}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(9.880066%, 58.651733%, 79.716492%)"
          offset={0.390625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(9.976196%, 58.869934%, 79.888916%)"
          offset={0.394531}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(10.073853%, 59.089661%, 80.062866%)"
          offset={0.398438}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(10.169983%, 59.309387%, 80.236816%)"
          offset={0.402344}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(10.267639%, 59.529114%, 80.410767%)"
          offset={0.40625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(10.36377%, 59.747314%, 80.583191%)"
          offset={0.410156}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(10.461426%, 59.967041%, 80.757141%)"
          offset={0.414063}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(10.557556%, 60.185242%, 80.929565%)"
          offset={0.417969}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(10.653687%, 60.404968%, 81.103516%)"
          offset={0.421875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(10.749817%, 60.623169%, 81.27594%)"
          offset={0.425781}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(10.847473%, 60.842896%, 81.44989%)"
          offset={0.429688}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(10.943604%, 61.062622%, 81.622314%)"
          offset={0.433594}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(11.04126%, 61.282349%, 81.796265%)"
          offset={0.4375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(11.13739%, 61.500549%, 81.970215%)"
          offset={0.441406}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(11.235046%, 61.720276%, 82.144165%)"
          offset={0.445313}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(11.331177%, 61.938477%, 82.316589%)"
          offset={0.449219}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(11.427307%, 62.158203%, 82.49054%)"
          offset={0.453125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(11.523438%, 62.37793%, 82.662964%)"
          offset={0.457031}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(11.621094%, 62.597656%, 82.836914%)"
          offset={0.460938}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(11.717224%, 62.815857%, 83.009338%)"
          offset={0.464844}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(11.81488%, 63.035583%, 83.183289%)"
          offset={0.46875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(11.911011%, 63.253784%, 83.355713%)"
          offset={0.472656}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(12.008667%, 63.473511%, 83.529663%)"
          offset={0.476562}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(12.104797%, 63.691711%, 83.702087%)"
          offset={0.480469}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(12.202454%, 63.911438%, 83.876038%)"
          offset={0.484375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(12.298584%, 64.131165%, 84.049988%)"
          offset={0.488281}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(12.394714%, 64.350891%, 84.223938%)"
          offset={0.492188}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(12.490845%, 64.569092%, 84.396362%)"
          offset={0.496094}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(12.588501%, 64.788818%, 84.570312%)"
          offset={0.5}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(12.684631%, 65.007019%, 84.742737%)"
          offset={0.503906}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(12.782288%, 65.226746%, 84.916687%)"
          offset={0.507812}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(12.878418%, 65.446472%, 85.089111%)"
          offset={0.511719}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(12.976074%, 65.666199%, 85.263062%)"
          offset={0.515625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(13.072205%, 65.884399%, 85.435486%)"
          offset={0.519531}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(13.169861%, 66.104126%, 85.609436%)"
          offset={0.523438}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(13.265991%, 66.322327%, 85.783386%)"
          offset={0.527344}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(13.362122%, 66.542053%, 85.957336%)"
          offset={0.53125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(13.458252%, 66.760254%, 86.129761%)"
          offset={0.535156}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(13.555908%, 66.97998%, 86.303711%)"
          offset={0.539062}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(13.652039%, 67.199707%, 86.476135%)"
          offset={0.542969}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(13.749695%, 67.419434%, 86.650085%)"
          offset={0.546875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(13.845825%, 67.637634%, 86.82251%)"
          offset={0.550781}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(13.943481%, 67.857361%, 86.99646%)"
          offset={0.554688}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(14.039612%, 68.075562%, 87.168884%)"
          offset={0.558594}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(14.135742%, 68.295288%, 87.342834%)"
          offset={0.5625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(14.231873%, 68.515015%, 87.516785%)"
          offset={0.566406}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(14.329529%, 68.734741%, 87.690735%)"
          offset={0.570313}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(14.425659%, 68.952942%, 87.863159%)"
          offset={0.574219}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(14.523315%, 69.172668%, 88.037109%)"
          offset={0.578125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(14.619446%, 69.390869%, 88.209534%)"
          offset={0.582031}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(14.717102%, 69.610596%, 88.383484%)"
          offset={0.585938}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(14.813232%, 69.828796%, 88.555908%)"
          offset={0.589844}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(14.910889%, 70.048523%, 88.729858%)"
          offset={0.59375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(15.007019%, 70.26825%, 88.902283%)"
          offset={0.597656}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(15.103149%, 70.487976%, 89.076233%)"
          offset={0.601562}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(15.19928%, 70.706177%, 89.250183%)"
          offset={0.605469}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(15.296936%, 70.925903%, 89.424133%)"
          offset={0.609375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(15.393066%, 71.144104%, 89.596558%)"
          offset={0.613281}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(15.490723%, 71.363831%, 89.770508%)"
          offset={0.617188}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(15.586853%, 71.583557%, 89.942932%)"
          offset={0.621094}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(15.684509%, 71.803284%, 90.116882%)"
          offset={0.625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(15.78064%, 72.021484%, 90.289307%)"
          offset={0.628906}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(15.878296%, 72.241211%, 90.463257%)"
          offset={0.632813}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(15.974426%, 72.459412%, 90.635681%)"
          offset={0.636719}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(16.070557%, 72.679138%, 90.809631%)"
          offset={0.640625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(16.166687%, 72.897339%, 90.982056%)"
          offset={0.644531}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(16.264343%, 73.117065%, 91.156006%)"
          offset={0.648438}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(16.360474%, 73.336792%, 91.329956%)"
          offset={0.652344}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(16.45813%, 73.556519%, 91.503906%)"
          offset={0.65625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(16.55426%, 73.774719%, 91.676331%)"
          offset={0.660156}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(16.651917%, 73.994446%, 91.850281%)"
          offset={0.664062}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(16.748047%, 74.212646%, 92.022705%)"
          offset={0.667969}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(16.845703%, 74.432373%, 92.196655%)"
          offset={0.671875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(16.941833%, 74.6521%, 92.36908%)"
          offset={0.675781}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(17.037964%, 74.871826%, 92.54303%)"
          offset={0.679688}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(17.134094%, 75.090027%, 92.715454%)"
          offset={0.683594}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(17.23175%, 75.309753%, 92.889404%)"
          offset={0.6875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(17.327881%, 75.527954%, 93.063354%)"
          offset={0.691406}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(17.425537%, 75.747681%, 93.237305%)"
          offset={0.695313}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(17.521667%, 75.965881%, 93.409729%)"
          offset={0.699219}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(17.619324%, 76.185608%, 93.583679%)"
          offset={0.703125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(17.715454%, 76.405334%, 93.756104%)"
          offset={0.707031}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(17.811584%, 76.625061%, 93.930054%)"
          offset={0.710938}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(17.907715%, 76.843262%, 94.102478%)"
          offset={0.714844}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(18.005371%, 77.062988%, 94.276428%)"
          offset={0.71875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(18.101501%, 77.281189%, 94.448853%)"
          offset={0.722656}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(18.199158%, 77.500916%, 94.622803%)"
          offset={0.726562}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(18.295288%, 77.720642%, 94.796753%)"
          offset={0.730469}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(18.392944%, 77.940369%, 94.970703%)"
          offset={0.734375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(18.489075%, 78.158569%, 95.143127%)"
          offset={0.738281}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(18.586731%, 78.378296%, 95.317078%)"
          offset={0.742188}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(18.682861%, 78.596497%, 95.489502%)"
          offset={0.746094}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(18.778992%, 78.816223%, 95.663452%)"
          offset={0.75}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(18.875122%, 79.034424%, 95.835876%)"
          offset={0.753906}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(18.972778%, 79.25415%, 96.009827%)"
          offset={0.757813}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(19.068909%, 79.473877%, 96.182251%)"
          offset={0.761719}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(19.166565%, 79.693604%, 96.356201%)"
          offset={0.765625}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(19.262695%, 79.911804%, 96.530151%)"
          offset={0.769531}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(19.360352%, 80.131531%, 96.704102%)"
          offset={0.773438}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(19.456482%, 80.349731%, 96.876526%)"
          offset={0.777344}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(19.554138%, 80.569458%, 97.050476%)"
          offset={0.78125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(19.650269%, 80.789185%, 97.2229%)"
          offset={0.785156}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(19.746399%, 81.008911%, 97.396851%)"
          offset={0.789062}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(19.842529%, 81.227112%, 97.569275%)"
          offset={0.792969}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(19.940186%, 81.446838%, 97.743225%)"
          offset={0.796875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(20.036316%, 81.665039%, 97.915649%)"
          offset={0.800781}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(20.133972%, 81.884766%, 98.0896%)"
          offset={0.804688}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(20.230103%, 82.104492%, 98.26355%)"
          offset={0.808594}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(20.327759%, 82.324219%, 98.4375%)"
          offset={0.8125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(20.423889%, 82.542419%, 98.609924%)"
          offset={0.816406}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(20.52002%, 82.762146%, 98.783875%)"
          offset={0.820312}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(20.61615%, 82.980347%, 98.956299%)"
          offset={0.824219}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(20.713806%, 83.200073%, 99.130249%)"
          offset={0.828125}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(20.809937%, 83.418274%, 99.302673%)"
          offset={0.832031}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(20.907593%, 83.638%, 99.476624%)"
          offset={0.835938}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(21.003723%, 83.857727%, 99.649048%)"
          offset={0.839844}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(21.101379%, 84.077454%, 99.822998%)"
          offset={0.84375}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(21.150208%, 84.187317%, 99.911499%)"
          offset={0.875}
        />
        <stop
          stopOpacity={1}
          stopColor="rgb(21.199036%, 84.298706%, 100%)"
          offset={1}
        />
      </linearGradient>
    </defs>
    <path
      strokeLinecap="butt"
      transform="matrix(0.75, 0, 0, 0.75, 85.313245, 2.891016)"
      fill="none"
      strokeLinejoin="miter"
      d="M 30.1813 298.858873 L 97.217763 141.686987 L 162.691725 141.686987 L 160.993809 298.858873 L 118.191722 298.858873 L 117.764639 283.692205 L 84.176095 245.853661 L 120.035472 245.853661 L 121.592764 186.327615 L 121.025056 181.796365 L 97.358388 240.041161 L 68.306302 240.041161 L 88.144845 263.145329 L 72.983386 298.858873 Z M 30.1813 298.858873 "
      stroke="#ffffff"
      strokeWidth={11.247381}
      strokeOpacity={1}
      strokeMiterlimit={4}
    />
    <g fill="#ff914d" fillOpacity={1}>
      <g transform="translate(100.934338, 227.141002)">
        <g>
          <path d="M 7.015625 -0.109375 L 57.296875 -117.984375 L 106.40625 -117.984375 L 105.125 -0.109375 L 73.015625 -0.109375 L 72.703125 -11.484375 L 47.515625 -39.859375 L 74.40625 -39.859375 L 75.578125 -84.5 L 75.15625 -87.90625 L 57.390625 -44.21875 L 35.609375 -44.21875 L 50.484375 -26.890625 L 39.109375 -0.109375 Z M 7.015625 -0.109375 " />
        </g>
      </g>
    </g>
    <path
      strokeLinecap="butt"
      transform="matrix(0.75, 0, 0, 0.75, 168.685203, 2.891016)"
      fill="none"
      strokeLinejoin="miter"
      d="M 37.414523 141.686987 L 98.638486 141.686987 L 70.857234 268.530746 L 87.159318 268.530746 L 90.414527 253.791162 L 129.674946 253.791162 L 119.893696 298.858873 L 24.659314 298.858873 L 54.279108 163.655739 Z M 37.414523 141.686987 "
      stroke="#ffffff"
      strokeWidth={11.247381}
      strokeOpacity={1}
      strokeMiterlimit={4}
    />
    <g fill="#1791c8" fillOpacity={1}>
      <g transform="translate(187.071921, 227.141002)">
        <g>
          <path d="M 9.671875 -117.984375 L 55.59375 -117.984375 L 34.75 -22.859375 L 46.984375 -22.859375 L 49.421875 -33.90625 L 78.875 -33.90625 L 71.53125 -0.109375 L 0.109375 -0.109375 L 22.328125 -101.515625 Z M 9.671875 -117.984375 " />
        </g>
      </g>
    </g>
    <g fill="#ff914d" fillOpacity={1}>
      <g transform="translate(122.992995, 254.057283)">
        <g>
          <path d="M 4.84375 -1.578125 C 4.789062 -1.328125 4.738281 -1.082031 4.6875 -0.84375 C 4.632812 -0.601562 4.582031 -0.359375 4.53125 -0.109375 C 2.539062 0.015625 1.164062 -0.0703125 0.40625 -0.375 C 1.28125 -3.664062 2.023438 -6.390625 2.640625 -8.546875 C 3.253906 -10.703125 3.738281 -12.316406 4.09375 -13.390625 C 5.726562 -13.765625 7.207031 -13.898438 8.53125 -13.796875 C 9.28125 -11.878906 10.007812 -9.890625 10.71875 -7.828125 C 11.4375 -5.773438 12.144531 -3.628906 12.84375 -1.390625 C 12.175781 -1.003906 10.765625 -0.679688 8.609375 -0.421875 C 8.535156 -0.671875 8.460938 -0.914062 8.390625 -1.15625 C 8.328125 -1.394531 8.257812 -1.628906 8.1875 -1.859375 C 7.726562 -1.828125 7.222656 -1.785156 6.671875 -1.734375 C 6.128906 -1.679688 5.519531 -1.628906 4.84375 -1.578125 Z M 7.1875 -5.15625 C 7.03125 -5.695312 6.882812 -6.179688 6.75 -6.609375 C 6.613281 -7.046875 6.484375 -7.421875 6.359375 -7.734375 C 6.242188 -7.273438 6.128906 -6.820312 6.015625 -6.375 C 5.898438 -5.9375 5.78125 -5.488281 5.65625 -5.03125 Z M 7.1875 -5.15625 " />
        </g>
      </g>
    </g>
    <g fill="#ff914d" fillOpacity={1}>
      <g transform="translate(136.234964, 254.057283)">
        <g>
          <path d="M 3.75 -6.53125 C 3.257812 -7.164062 2.734375 -7.84375 2.171875 -8.5625 C 1.617188 -9.289062 1.03125 -10.066406 0.40625 -10.890625 C 0.945312 -11.640625 2.046875 -12.539062 3.703125 -13.59375 C 3.804688 -13.488281 4.070312 -13.164062 4.5 -12.625 C 4.925781 -12.082031 5.519531 -11.3125 6.28125 -10.3125 C 7.101562 -11.519531 7.726562 -12.429688 8.15625 -13.046875 C 8.582031 -13.671875 8.828125 -14.007812 8.890625 -14.0625 C 9.597656 -13.675781 10.226562 -13.316406 10.78125 -12.984375 C 11.34375 -12.648438 11.8125 -12.335938 12.1875 -12.046875 C 11.550781 -11.085938 10.953125 -10.1875 10.390625 -9.34375 C 9.828125 -8.507812 9.300781 -7.722656 8.8125 -6.984375 C 9.300781 -6.328125 9.828125 -5.628906 10.390625 -4.890625 C 10.953125 -4.148438 11.550781 -3.351562 12.1875 -2.5 C 11.21875 -1.539062 10.117188 -0.675781 8.890625 0.09375 C 8.785156 0.0195312 7.914062 -1.070312 6.28125 -3.1875 C 5.519531 -2.050781 4.925781 -1.171875 4.5 -0.546875 C 4.070312 0.078125 3.804688 0.445312 3.703125 0.5625 C 2.046875 -0.195312 0.945312 -0.90625 0.40625 -1.5625 C 1.03125 -2.5 1.617188 -3.378906 2.171875 -4.203125 C 2.734375 -5.023438 3.257812 -5.800781 3.75 -6.53125 Z M 3.75 -6.53125 " />
        </g>
      </g>
    </g>
    <g fill="#ff914d" fillOpacity={1}>
      <g transform="translate(148.826222, 254.057283)">
        <g>
          <path d="M 4.578125 -13.4375 C 4.628906 -11.320312 4.65625 -9.1875 4.65625 -7.03125 C 4.664062 -4.882812 4.648438 -2.691406 4.609375 -0.453125 C 3.398438 -0.253906 2.039062 -0.125 0.53125 -0.0625 C 0.40625 -3.15625 0.375 -7.488281 0.4375 -13.0625 C 1.175781 -13.207031 1.882812 -13.304688 2.5625 -13.359375 C 3.25 -13.421875 3.921875 -13.445312 4.578125 -13.4375 Z M 4.578125 -13.4375 " />
        </g>
      </g>
    </g>
    <g fill="#ff914d" fillOpacity={1}>
      <g transform="translate(153.901768, 254.057283)">
        <g>
          <path d="M 2.75 -1.015625 C 1.800781 -1.671875 1.117188 -2.640625 0.703125 -3.921875 C 0.484375 -4.734375 0.390625 -5.53125 0.421875 -6.3125 C 0.847656 -11.039062 3.3125 -13.546875 7.8125 -13.828125 C 9.644531 -13.992188 11.164062 -13.363281 12.375 -11.9375 C 12.914062 -11.101562 13.242188 -10.148438 13.359375 -9.078125 C 13.460938 -7.929688 13.320312 -6.734375 12.9375 -5.484375 C 12.445312 -4.117188 11.796875 -3.046875 10.984375 -2.265625 C 8.398438 0.148438 5.65625 0.566406 2.75 -1.015625 Z M 9.234375 -8 C 8.910156 -8.976562 8.234375 -9.425781 7.203125 -9.34375 C 6.316406 -9.3125 5.597656 -8.953125 5.046875 -8.265625 C 4.453125 -7.398438 4.300781 -6.523438 4.59375 -5.640625 C 5.070312 -4.703125 5.769531 -4.285156 6.6875 -4.390625 C 7.757812 -4.566406 8.550781 -5.222656 9.0625 -6.359375 C 9.289062 -6.941406 9.347656 -7.488281 9.234375 -8 Z M 9.234375 -8 " />
        </g>
      </g>
    </g>
    <g fill="#ff914d" fillOpacity={1}>
      <g transform="translate(167.713115, 254.057283)">
        <g>
          <path d="M 4.15625 -4.6875 C 3.351562 -4.6875 2.671875 -4.867188 2.109375 -5.234375 C 1.378906 -5.765625 0.929688 -6.289062 0.765625 -6.8125 C 0.265625 -8.394531 0.4375 -9.851562 1.28125 -11.1875 C 2.957031 -13.570312 5.96875 -14.019531 10.3125 -12.53125 L 9.53125 -9.1875 C 5.957031 -9.957031 4.253906 -9.804688 4.421875 -8.734375 C 4.523438 -8.304688 4.882812 -8.125 5.5 -8.1875 C 5.78125 -8.21875 6.054688 -8.238281 6.328125 -8.25 C 6.609375 -8.257812 6.875 -8.257812 7.125 -8.25 C 7.957031 -8.226562 8.632812 -8.039062 9.15625 -7.6875 C 9.90625 -7.175781 10.359375 -6.648438 10.515625 -6.109375 C 11.035156 -4.535156 10.859375 -3.082031 9.984375 -1.75 C 8.296875 0.664062 5.101562 1.085938 0.40625 -0.484375 L 1.1875 -3.796875 C 5.113281 -3.078125 7.007812 -3.210938 6.875 -4.203125 C 6.769531 -4.617188 6.410156 -4.800781 5.796875 -4.75 C 5.523438 -4.726562 5.253906 -4.710938 4.984375 -4.703125 C 4.710938 -4.691406 4.4375 -4.6875 4.15625 -4.6875 Z M 4.15625 -4.6875 " />
        </g>
      </g>
    </g>
    <g clipPath="url(#4e2b4df623)">
      <g clipPath="url(#d3059ca50c)">
        <path
          fill="url(#fb9e3a2f74)"
          d="M 227.742188 167.671875 L 227.742188 201.019531 L 270.929688 201.019531 L 270.929688 167.671875 Z M 227.742188 167.671875 "
          fillRule="nonzero"
        />
      </g>
    </g>
    <g clipPath="url(#1c9d33c84b)">
      <g clipPath="url(#0ada538eb3)">
        <path
          fill="url(#148746cd6c)"
          d="M 230.742188 174.398438 L 230.742188 206.742188 L 273.421875 206.742188 L 273.421875 174.398438 Z M 230.742188 174.398438 "
          fillRule="nonzero"
        />
      </g>
    </g>
    <g clipPath="url(#e57e83149e)">
      <g clipPath="url(#e9bb31364a)">
        <path
          fill="url(#3d69a65185)"
          d="M 220.621094 195.789062 L 220.621094 251.269531 L 286.234375 251.269531 L 286.234375 195.789062 Z M 220.621094 195.789062 "
          fillRule="nonzero"
        />
      </g>
    </g>
    <g clipPath="url(#67fe13225f)">
      <g clipPath="url(#0d16d4f87b)">
        <path
          fill="url(#34f14438b6)"
          d="M 210.488281 203.082031 L 210.488281 260.488281 L 284.539062 260.488281 L 284.539062 203.082031 Z M 210.488281 203.082031 "
          fillRule="nonzero"
        />
      </g>
    </g>
    <g fill="#1791c8" fillOpacity={1}>
      <g transform="translate(192.766596, 253.024893)">
        <g>
          <path d="M 4.359375 -13.4375 L 4.375 -4.09375 C 6.46875 -4.320312 8.164062 -4.46875 9.46875 -4.53125 C 9.5 -3.21875 9.484375 -2.003906 9.421875 -0.890625 C 7.304688 -0.578125 4.320312 -0.300781 0.46875 -0.0625 C 0.414062 -2.019531 0.394531 -4.078125 0.40625 -6.234375 C 0.425781 -8.398438 0.46875 -10.691406 0.53125 -13.109375 C 1.925781 -13.398438 3.203125 -13.507812 4.359375 -13.4375 Z M 4.359375 -13.4375 " />
        </g>
      </g>
    </g>
    <g fill="#1791c8" fillOpacity={1}>
      <g transform="translate(202.671771, 253.024893)">
        <g>
          <path d="M 4.578125 -13.4375 C 4.628906 -11.320312 4.65625 -9.1875 4.65625 -7.03125 C 4.664062 -4.882812 4.648438 -2.691406 4.609375 -0.453125 C 3.398438 -0.253906 2.039062 -0.125 0.53125 -0.0625 C 0.40625 -3.15625 0.375 -7.488281 0.4375 -13.0625 C 1.175781 -13.195312 1.882812 -13.296875 2.5625 -13.359375 C 3.25 -13.421875 3.921875 -13.445312 4.578125 -13.4375 Z M 4.578125 -13.4375 " />
        </g>
      </g>
    </g>
    <g fill="#1791c8" fillOpacity={1}>
      <g transform="translate(207.746344, 253.024893)">
        <g>
          <path d="M 0.46875 -0.078125 C 0.425781 -4.203125 0.40625 -7.335938 0.40625 -9.484375 C 0.414062 -11.640625 0.453125 -12.8125 0.515625 -13 C 2.085938 -13.21875 3.628906 -13.394531 5.140625 -13.53125 C 6.660156 -13.664062 8.160156 -13.769531 9.640625 -13.84375 C 9.722656 -12.894531 9.722656 -11.738281 9.640625 -10.375 C 9.441406 -10.3125 7.691406 -10.132812 4.390625 -9.84375 L 4.375 -8.359375 C 5.957031 -8.515625 7.164062 -8.625 8 -8.6875 C 8.84375 -8.757812 9.316406 -8.789062 9.421875 -8.78125 L 9.421875 -5.25 L 4.328125 -4.765625 C 4.378906 -2.078125 4.347656 -0.609375 4.234375 -0.359375 C 1.960938 -0.128906 0.707031 -0.0351562 0.46875 -0.078125 Z M 0.46875 -0.078125 " />
        </g>
      </g>
    </g>
    <g fill="#1791c8" fillOpacity={1}>
      <g transform="translate(217.862959, 253.024893)">
        <g>
          <path d="M 9.640625 -13.34375 C 9.703125 -12.71875 9.726562 -12.109375 9.71875 -11.515625 C 9.707031 -10.921875 9.675781 -10.335938 9.625 -9.765625 L 4.328125 -9.25 L 4.34375 -8 L 9.4375 -8.4375 L 9.4375 -5.015625 C 9.332031 -4.992188 8.859375 -4.941406 8.015625 -4.859375 C 7.179688 -4.785156 5.972656 -4.675781 4.390625 -4.53125 L 4.40625 -3.34375 C 7.695312 -3.726562 9.445312 -3.910156 9.65625 -3.890625 C 9.757812 -2.492188 9.757812 -1.328125 9.65625 -0.390625 C 7.507812 -0.0664062 4.457031 0.191406 0.5 0.390625 C 0.445312 -1.554688 0.414062 -3.597656 0.40625 -5.734375 C 0.40625 -7.867188 0.429688 -10.109375 0.484375 -12.453125 C 3.984375 -12.941406 7.035156 -13.238281 9.640625 -13.34375 Z M 9.640625 -13.34375 " />
        </g>
      </g>
    </g>
  </svg>
);
export default SVGComponent;
