// src/ComingSoon.tsx
import React from 'react';

const ComingSoon: React.FC = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Coming Soon</h1>
      <p style={styles.message}>We're working hard to bring you something great!</p>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f0f8ff', // Light blue background
    color: '#003366', // Dark blue text
    textAlign: 'center' as 'center', // TypeScript fix for textAlign
  },
  title: {
    fontSize: '3rem',
    margin: '0',
  },
  message: {
    fontSize: '1.5rem',
    margin: '1rem 0',
  },
};

export default ComingSoon;